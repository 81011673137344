import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cursor from '../components/Cursor';



function IndexPage() {
 return (
  <Layout>
    <Seo title="Kinetic Leaders" />
    <div className="container">
      <div className="row col-1">
        <StaticImage
          src="../images/logo.png"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Kinetic Leadership"
          style={{ marginBottom: `1.45rem` }}
        />
      </div>
      <div className="row col-2">
        <div className="wrap-col">
          <h1><strong>New Website</strong> <br/>Under Construction</h1>
          <p>We are currently working on our new website, please come back soon.</p>
        </div>
      </div>
    </div>
     <div className="background-triangles">
       <div className="triangle triangle-1">
         <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.04 28.91">
           <path d="M0 14.46L25.04 28.91 25.04 0 0 14.46z" fill="#cd141d" className="cls-1 magnet"></path>
         </svg>
       </div>

       <div className="triangle triangle-2">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.04 28.91">
           <path d="M0 14.46L25.04 28.91 25.04 0 0 14.46z" fill="#cd141d" className="cls-1"></path>
         </svg>
       </div>

       <div className="triangle triangle-3">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.04 28.91">
           <path d="M0 14.46L25.04 28.91 25.04 0 0 14.46z" fill="#cd141d" className="cls-1"></path>
         </svg>
       </div>

       <div className="triangle triangle-4">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.04 28.91">
           <path d="M0 14.46L25.04 28.91 25.04 0 0 14.46z" fill="#cd141d" className="cls-1"></path>
         </svg>
       </div>
     </div>
     <Cursor />
  </Layout>
)
}

export default IndexPage
